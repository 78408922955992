import { PlannerContext } from "@/contexts/PlannerContext";
import { CheckIcon, PlusIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { observer } from "mobx-react";
import { useMemo, useContext, useState } from "react";
import {
  SHOPPING_LIST_GUID,
  addRecipeToShoppingList,
  removeRecipeFromShoppingList,
} from "@/components/ShoppingList/ShoppingListController";
import { useStores } from "@/hooks/useStores";
import Spinner from "@components/Spinner";
import { debounce } from "lodash";
import usePaywall from "@/hooks/usePaywall";
import useSubscriptionPermission from "@/hooks/useSubscriptionPermission";
import TooltipPopover from "@/components/TooltipPopover";

type Recipe = {
  id: number;
  [key: string]: unknown;
};

const SaveRecipeToShoppingList = ({ recipe }: { recipe: Recipe }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { planners, setPlanners, updatePlanner } = useContext(PlannerContext);
  const { authStore } = useStores();
  const currentUser = authStore.currentUser;
  const { setPaywall } = usePaywall();
  const { subscriptionPermission } = useSubscriptionPermission(recipe);
  const { canUsePlanner } = subscriptionPermission;

  const shoppingList = useMemo(() => {
    return (
      currentUser &&
      planners?.find((planner) => planner.title === SHOPPING_LIST_GUID)
    );
  }, [planners, currentUser]);

  const recipeInShoppingList = useMemo(() => {
    if (!shoppingList || !shoppingList.days) return false;

    return shoppingList.days[0].dayRecipes.some(
      (dayRecipe) => dayRecipe.recipeId.toString() === recipe.id.toString()
    );
  }, [shoppingList]);

  const handleRemoveFromShoppingList = debounce(
    async () => {
      if (!canUsePlanner) {
        setPaywall({
          isOpen: true,
          isClosable: true,
          variant: `addToShoppingList`,
        });

        return;
      }

      if (isLoading) return;

      try {
        setIsLoading(true);

        const newPlanner = await removeRecipeFromShoppingList({
          recipe,
          shoppingList,
        });

        updatePlanner(shoppingList.uid, newPlanner, true);
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsLoading(false);
      }
    },
    1000,
    { leading: true, trailing: false }
  );

  const handleAddToShoppingList = debounce(
    async () => {
      if (!canUsePlanner) {
        setPaywall({
          isOpen: true,
          isClosable: true,
          variant: `addToShoppingList`,
        });

        return;
      }

      if (isLoading) return;

      try {
        setIsLoading(true);
        const response = await addRecipeToShoppingList({
          recipe,
          shoppingList,
          currentUser,
        });

        if (shoppingList) {
          updatePlanner(shoppingList.uid, response, true);
        } else {
          setPlanners([...planners, response]);
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsLoading(false);
      }
    },
    1000,
    { leading: true, trailing: false }
  );

  return (
    <div className="SaveRecipe">
      <TooltipPopover
        disabled={isLoading}
        onClick={
          recipeInShoppingList
            ? handleRemoveFromShoppingList
            : handleAddToShoppingList
        }
        side="right"
        avoidCollisions
        hideWhenDetached={false}
      >
        <TooltipPopover.TooltipTrigger
          className={clsx(
            "w-component h-component flex shrink-0 items-center justify-center space-x-1.5 rounded-full bg-zinc-950 text-zinc-50 transition ease-out  hover:bg-white hover:text-zinc-950"
          )}
          label="Add to Shopping List"
        >
          {isLoading ? (
            <Spinner />
          ) : recipeInShoppingList ? (
            <CheckIcon className="size-5 stroke-[1.5]" />
          ) : (
            <PlusIcon className="size-5 stroke-[1.5]" />
          )}
        </TooltipPopover.TooltipTrigger>
        <TooltipPopover.TooltipContent>
          Add to Shopping List
        </TooltipPopover.TooltipContent>
      </TooltipPopover>
    </div>
  );
};

export default observer(SaveRecipeToShoppingList);
